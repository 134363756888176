export default {
  path: '/intentional-manage',
  title: '线索管理',
  children: [{
    path: '/intentional-customer',
    title: '线索',
    children: [{
      path: '/intentional-customer/detail',
      hide: true,
      title: '线索详情'
    }]
  }, {
    path: '/intentional-recycle',
    title: '回收站',
    children: [{
      path: '/intentional-recycle/details',
      hide: true,
      title: '回收站详情'
    }]
  }]
};
import "core-js/modules/es6.array.find-index";
import "core-js/modules/web.dom.iterable";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.number.constructor";
import { debounce } from 'xe-utils';
// 防抖
var search = debounce(function () {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  this.$emit('on-remote-search', value);
}, 500);
export default {
  name: 'fk-transfer',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    leftList: {
      // 左侧 查询出来的 list ，在外部维护
      type: Array,
      default: function _default() {
        return [];
      }
    },
    checkedList: {
      // 初始化时，已经选中的
      type: Array,
      default: function _default() {
        return [];
      }
    },
    limitMax: {
      type: Number,
      default: 0 // 0表示不限制
    },
    footerDesc: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: function _default() {
        return {
          // 配置 check 的 label 取值配置
          valueKey: 'id',
          labelKey: 'name',
          descKey: 'desc'
        };
      }
    }
  },
  data: function data() {
    return {
      // 搜索关键字
      searchWord: null,
      // 全选状态
      checkAll: false,
      // 右侧 list
      rightList: [],
      // 当前左侧需要展示为勾选的选项 id,需要在 leftList 变化后重新初始化
      checkedIds: []
    };
  },
  computed: {
    label: function label() {
      var _this = this;
      return function (item) {
        var label = item[_this.options.labelKey];
        var remark = item[_this.options.descKey] ? "\uFF08".concat(item[_this.options.descKey], "\uFF09") : '';
        var desc1 = _this.options.descKey1 ? item[_this.options.descKey1] ? "[".concat(item[_this.options.descKey1], "]") : '' : '';
        return "".concat(label).concat(remark).concat(desc1);
      };
    }
  },
  watch: {
    leftList: function leftList() {
      this.initLeftListChecked();
      this.setCheckAllStatus();
    }
  },
  created: function created() {
    // 初始化 右边的选项
    if (this.checkedList) {
      this.rightList = _toConsumableArray(this.checkedList);
    }
    this.initLeftListChecked();
    this.setCheckAllStatus();
  },
  methods: {
    initLeftListChecked: function initLeftListChecked() {
      var _this2 = this;
      // 初始化左边选中效果
      this.leftList.forEach(function (v, i) {
        if (_this2.rightList.some(function (vv) {
          return vv[_this2.options.valueKey] === v[_this2.options.valueKey];
        })) {
          _this2.$set(_this2.leftList[i], 'checked', true);
        } else {
          _this2.$set(_this2.leftList[i], 'checked', false);
        }
      });
    },
    // 搜索词变化 ，触发外部搜索
    searchWordChange: function searchWordChange() {
      search.call(this, this.searchWord);
    },
    // 左侧全选
    handleCheckAll: function handleCheckAll(isCheck) {
      var _this3 = this;
      this.checkAll = isCheck;
      this.leftList.forEach(function (v, i) {
        _this3.$set(_this3.leftList[i], 'checked', isCheck);
        // 存在
        if (_this3.rightList.some(function (vv) {
          return vv[_this3.options.valueKey] === v[_this3.options.valueKey];
        })) {
          // 取消 删除右边
          if (!isCheck) {
            var index = _this3.rightList.findIndex(function (item) {
              return v[_this3.options.valueKey] === item[_this3.options.valueKey];
            });
            if (index >= 0) _this3.rightList.splice(index, 1);
          }
        } else {
          // 选中 push右边
          if (isCheck) {
            _this3.rightList.push(v);
          }
        }
      });
    },
    // 当前选择 变化
    checkChange: function checkChange(isCheck, item) {
      var _this4 = this;
      // 选中 添加到右边
      if (isCheck) {
        this.rightList.push(item);
      } // 取消选中 删除右边
      else {
        var index = this.rightList.findIndex(function (v) {
          return v[_this4.options.valueKey] === item[_this4.options.valueKey];
        });
        if (index >= 0) this.rightList.splice(index, 1);
      }
      this.setCheckAllStatus();
    },
    // 设置全选check状态
    setCheckAllStatus: function setCheckAllStatus() {
      if (!this.leftList || this.leftList.length === 0) {
        this.checkAll = false;
        return;
      }
      this.checkAll = this.leftList.every(function (v) {
        return v.checked;
      });
    },
    deleteItem: function deleteItem(index, item) {
      var _this5 = this;
      var leftIndex = this.leftList.findIndex(function (v) {
        return v[_this5.options.valueKey] === item[_this5.options.valueKey];
      });
      if (leftIndex >= 0) {
        this.leftList[leftIndex].checked = false;
      }
      this.checkChange(false, item);
    },
    save: function save() {
      this.$emit('save', this.rightList);
    },
    cancel: function cancel() {
      this.$emit('cancel');
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "system-notification-box"
  }, [_c("i", {
    staticClass: "iconfont iconxitonggonggao"
  }), _c("div", {
    staticClass: "system-notification-content text-eli cursor-point",
    on: {
      click: _vm.showSystemNotificationModel
    }
  }, [_c("span", [_vm._v("系统公告：")]), _c("span", [_vm._v(_vm._s(_vm.newNotice.title))])])]), _c("Modal", {
    attrs: {
      "footer-hide": true,
      width: 750
    },
    model: {
      value: _vm.systemNotificationModel,
      callback: function callback($$v) {
        _vm.systemNotificationModel = $$v;
      },
      expression: "systemNotificationModel"
    }
  }, [_c("div", {
    staticClass: "ivu-modal-header-inner",
    staticStyle: {
      width: "97%"
    },
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.newNotice.title) + "\n\t\t")]), _c("p", {
    staticClass: "fk-notice",
    domProps: {
      innerHTML: _vm._s(_vm.newNotice.noticeText)
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
export default {
  path: '/home',
  name: 'home',
  meta: {
    auth: true,
    title: '工作台'
  },
  component: function component() {
    return import('@/pages/dsp/home');
  }
};
export default {
  path: '/customer-manage',
  title: '销售管理',
  children: [{
    path: '/follow-up-customer',
    title: '客户',
    children: [{
      path: '/follow-up-customer/detail',
      hide: true,
      title: '客户详情'
    }, {
      path: '/high-seas-recycle',
      hide: true,
      title: '公海将回收的客户'
    }, {
      path: '/forget-customer',
      hide: true,
      title: '遗忘客户'
    }]
  }, {
    path: '/sales-opportunity',
    title: '销售机会',
    children: [{
      path: '/sales-opportunity/detail',
      hide: true,
      title: '销售机会详情'
    }]
  }, {
    path: '/high-seas',
    title: '公海'
  }, {
    path: '/contacts-manage',
    title: '联系人',
    children: [{
      path: '/customer-manage/contact/detail',
      hide: true,
      title: '联系人详情'
    }]
  }, {
    path: '/follow-manage',
    title: '跟进管理'
  }]
};
import dayjs from 'dayjs';
export default {
  name: 'dsp-date-picker',
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    splitPanels: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    value: {
      type: Array
    },
    placeholder: {
      type: String,
      default: '创建时间'
    },
    transfer: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {
        return {
          disabledDate: function disabledDate(data) {
            return dayjs(data).format('YYYY') < '2019';
          }
        };
      }
    }
  },
  data: function data() {
    return {
      date: []
    };
  },
  watch: {
    value: function value(newVal) {
      this.date = newVal;
    }
  },
  methods: {
    dateChange: function dateChange(date) {
      this.$emit('input', date);
      this.$emit('on-change', date);
    }
  }
};
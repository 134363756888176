export default {
  path: '/account-manage',
  title: '账号管理',
  children: [{
    path: '/account-manage/account-apply',
    title: '账号申请',
    children: [{
      path: '/account-manage/write-email',
      hide: true,
      title: '写邮件'
    }, {
      path: '/account-manage/apply-account',
      hide: true,
      title: '申请账号'
    }]
  }, {
    path: '/account-manage/account-manage',
    title: '账号管理',
    children: [{
      path: '/account-manage/append-data',
      hide: true,
      title: '申请追加'
    }, {
      path: '/account-manage/account-manage/detail',
      hide: true,
      title: '申请追加'
    }]
  }, {
    path: '/account-manage/address-book',
    title: '通讯录'
  }]
};
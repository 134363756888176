import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import iMenuSide from "./menu-side";
import iHeaderUser from "./header-user";
import iTabs from "./tabs";
import { mapState, mapMutations } from 'vuex';
import Setting from '@/setting';
import SystemNotice from '@/layouts/basic-layout/system-notice/index.vue';
export default {
  name: 'BasicLayout',
  components: {
    SystemNotice: SystemNotice,
    iHeaderUser: iHeaderUser,
    iTabs: iTabs,
    iMenuSide: iMenuSide
  },
  data: function data() {
    return {
      ticking: false,
      oldScrollTop: 0,
      loadRouter: true,
      systemNotificationNotReadCount: 0
    };
  },
  provide: function provide() {
    return {
      layout: this
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['tabs', 'menuCollapse'])), mapState('admin/page', ['keepAlive'])), {}, {
    headerClasses: function headerClasses() {
      return [{
        'i-layout-header-fix': true,
        'i-layout-header-fix-collapse': this.menuCollapse
      }];
    },
    siderClasses: function siderClasses() {
      return {
        'i-layout-sider-fix': true
      };
    },
    contentClasses: function contentClasses() {
      return {
        'i-layout-content-fix-with-header': true,
        'i-layout-content-with-tabs': true,
        'i-layout-content-with-tabs-fix': true
      };
    },
    insideClasses: function insideClasses() {
      return {
        'i-layout-inside-fix-with-sider': true,
        'i-layout-inside-fix-with-sider-collapse': this.menuCollapse
      };
    },
    menuSideWidth: function menuSideWidth() {
      return this.menuCollapse ? 80 : Setting.menuSideWidth;
    }
  }),
  watch: {
    '$route': function $route(to, from) {
      if (!to.params.noCache) {
        this.handleReload(to.name);
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/page', ['keepAliveRemove', 'keepAlivePush'])), {}, {
    handleReload: function handleReload(name) {
      var _this = this;
      // 先移除，再加载，强制刷新子页面
      this.keepAliveRemove(name);
      this.loadRouter = false;
      this.$nextTick(function () {
        return _this.loadRouter = true;
      });
    },
    afterRouterChange: function afterRouterChange() {
      // 记录子组件name，用于keepalive
      var childName = this.$refs.child.$options.name;
      if (!this.keepAlive.includes(childName)) {
        this.keepAlivePush(childName);
      }
    }
  }),
  created: function created() {}
};
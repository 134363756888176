var render = function render() {
  var _this = this;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "qiniu-upload",
    staticClass: "qiniu-upload"
  }, [_c("Button", {
    attrs: {
      disabled: !_vm.upToken,
      loading: _vm.isUploading,
      icon: "ios-cloud-upload-outline"
    },
    on: {
      click: function click() {
        _this.$refs["file"].click();
      }
    }
  }, [_vm._t("default", function () {
    return [_vm._v("选择文件")];
  })], 2), _vm.fileName && _vm.showFile ? _c("div", {
    staticClass: "file-name"
  }, [_vm.fileUrl ? _c("a", {
    staticStyle: {
      color: "#515a6e"
    },
    attrs: {
      href: _vm.fileUrl,
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.fileName) + "（" + _vm._s(_vm._f("formatSize")(_vm.totalSize)) + "）")]) : _c("span", [_vm._v(_vm._s(_vm.fileName) + "（" + _vm._s(_vm._f("formatSize")(_vm.totalSize)) + "）")]), _c("Icon", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      color: "red",
      size: "16",
      type: "md-close-circle"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.cancel.apply(null, arguments);
      }
    }
  })], 1) : _vm._e(), _vm.fileName && _vm.showProgress ? _c("Progress", {
    attrs: {
      percent: _vm.percent,
      "stroke-width": 5,
      status: "active"
    }
  }) : _vm._e(), _c("input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file",
    attrs: {
      accept: _vm.accept,
      type: "file"
    },
    on: {
      change: _vm.selectFile
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import { getAuthority, getExamineOrApply } from '@/libs/auth';
export default {
  path: '/contract-manage/seal-application/edit',
  name: 'seal-application-edit',
  meta: {
    auth: true,
    title: '新建/编辑用章申请'
  },
  component: function component() {
    return import('@/pages/dsp/contract-manage/seal-application/edit');
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission;
  //     if(to.query.id){
  //         isPermission = await getExamineOrApply(to.query.id);
  //     }else{
  //         isPermission = await getAuthority(263);
  //     }
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};
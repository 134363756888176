import Vue from 'vue';
import Loading from "../components/fk/fk-loading";
export default {
  bind: function bind(el, _bind) {
    // el 绑定的dom，bind绑定实例：vue的bind实例
    var Constructor = Vue.extend(Loading);
    var instance = new Constructor({
      propsData: {
        text: _bind.arg || null
      }
    }).$mount();
    instance.visible = _bind.value;
    el._ht_loading_component = instance; // 把loading组件 挂载到el上（全局）
    el.insertBefore(instance.$el, el.firstChild);
    el.style.position = 'relative';
    if (_bind.value) {
      el.style.overflow = 'hidden';
      el.style.overflowY = 'hidden';
    } else {
      el.style.overflow = 'auto';
      el.style.overflowY = 'auto';
    }
  },
  update: function update(el, bind) {
    el._ht_loading_component.visible = bind.value; // 更新组件实例的数据
    el._ht_loading_component.text = bind.arg;
    if (bind.value) {
      el.style.overflow = 'hidden';
      el.style.overflowY = 'hidden';
    } else {
      el.style.overflow = 'auto';
      el.style.overflowY = 'auto';
    }
  },
  unbind: function unbind(el) {
    el._ht_loading_component = undefined;
  }
};
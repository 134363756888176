var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-align-center"
  }, [!_vm.hideSite ? _c("dsp-site-icon", {
    attrs: {
      site: _vm.site,
      theme: "dark"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "flex-eli",
    class: {
      "ml-10": !_vm.hideSite
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("Tooltip", {
    attrs: {
      content: _vm.toolTipContentName,
      "max-width": "200",
      placement: "top",
      transfer: ""
    }
  }, [_c("div", {
    staticClass: "txtEli",
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.$Copy({
          text: _vm.toolTipContentName
        });
      }
    }
  }, [_vm.jumpToDetailsPath ? _c("a", {
    staticClass: "text-eli",
    attrs: {
      href: "javascript:void(0);"
    },
    on: {
      click: function click($event) {
        return _vm.jumpToDetails(_vm.row);
      }
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.toolTipContentName) + "\n\t\t\t\t\t")]) : _c("span", [_vm._v(" " + _vm._s(_vm.toolTipContentName))])])])], 1), _c("div", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("Tooltip", {
    attrs: {
      content: _vm.toolTipContentId,
      placement: "top",
      transfer: ""
    }
  }, [_c("div", {
    staticClass: "text-eli",
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.$Copy({
          text: _vm.toolTipContentId
        });
      }
    }
  }, [!_vm.noJumpId ? _c("a", {
    staticClass: "text-eli",
    attrs: {
      href: _vm.href,
      target: "_blank"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.toolTipContentId) + "\n\t\t\t\t\t")]) : _c("pre", {
    staticClass: "text-eli"
  }, [_vm._v(_vm._s(_vm.toolTipContentId))])])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-tooltip',
  props: {
    content: {
      type: [String, Number],
      default: ''
    },
    placement: {
      type: String,
      default: 'left'
    },
    delay: {
      type: Number,
      default: 500
    },
    theme: {
      type: String,
      default: 'dark'
    },
    maxWidth: {
      type: [String, Number],
      default: 200
    },
    transfer: {
      type: Boolean,
      default: true
    },
    lineClamp2: {
      // 默认双行截断，关闭则是单行截断
      type: Boolean,
      default: true
    },
    isCopy: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showTooltip: false
    };
  },
  methods: {
    copy: function copy(content) {
      if (this.isCopy) {
        this.$Copy({
          text: content
        });
      }
    }
  }
};
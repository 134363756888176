import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-tip',
  props: {
    //tooltip
    content: {
      type: [String, Number],
      default: ''
    },
    placement: {
      type: String,
      default: 'top'
    },
    delay: {
      type: Number,
      default: 0
    },
    theme: {
      type: String,
      default: 'dark'
    },
    maxWidth: {
      type: [String, Number],
      default: 200
    },
    transfer: {
      type: Boolean,
      default: true
    },
    //icon
    icon: {
      type: String,
      default: 'md-help-circle'
    },
    size: {
      type: [String, Number],
      default: '16'
    },
    color: {
      type: String,
      default: '#808695'
    }
  }
};
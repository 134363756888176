import { getAuth } from '@/libs/auth';
export default {
  path: '/forget-customer',
  name: 'forget-customer',
  meta: {
    auth: true,
    title: '遗忘客户'
  },
  component: function component() {
    return import('@/pages/dsp/customer-manage/forget-customer');
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuth("high-seas-recycle");
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_vm.showArrow ? _c("Icon", {
    staticStyle: {
      "font-size": "30px"
    },
    attrs: {
      type: "ios-arrow-back"
    }
  }) : _vm._e(), _c("RadioGroup", {
    attrs: {
      size: _vm.size,
      "button-style": "solid",
      type: "button"
    },
    on: {
      "on-change": _vm.change
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, _vm._l(_vm.radioGroupOptions, function (v, i) {
    return _c("Radio", {
      key: v.id + v.remarks + v.count,
      attrs: {
        label: v.id
      }
    }, [_vm._v(_vm._s(v.remarks) + " "), v.count ? _c("span", [_vm._v("(" + _vm._s(v.count) + ")")]) : _vm._e()]);
  }), 1), _vm.showArrow ? _c("Icon", {
    staticStyle: {
      "font-size": "30px"
    },
    attrs: {
      type: "ios-arrow-forward"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
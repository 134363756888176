export default {
  path: '/invoice-manage',
  title: '发票管理',
  children: [{
    path: '/invoice-manage/invoice',
    title: '发票管理',
    children: [{
      path: '/invoice-manage/invoice/detail',
      hide: true,
      title: '发票详情'
    }]
  }]
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "fk-star"
  }, _vm._l(_vm.levels, function (i) {
    return _c("li", {
      key: i,
      staticClass: "fk-star-item",
      on: {
        click: function click($event) {
          return _vm.update(i);
        }
      }
    }, [i > _vm.level ? _c("Icon", {
      attrs: {
        size: _vm.size,
        type: "md-star-outline"
      }
    }) : _c("Icon", {
      attrs: {
        size: _vm.size,
        type: "md-star"
      }
    })], 1);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import request from '@/plugins/request';
export var getPrepare = function getPrepare() {
  return request.get('/workBench/prepare');
};

// 待办事项
export var getTodoInfo = function getTodoInfo() {
  return request.get('/workBench/todo');
};
export var getSealApplyAndChange = function getSealApplyAndChange() {
  return request.get('/workBench/todo/sealApplyAndChange');
};

// 待办事项-平帐审批
export var getBalanceAccount = function getBalanceAccount(params) {
  return request.get('/workBench/todo/balanceAccountCount');
};

// 待办事项-月账单计划
export var getWarnPlanCount = function getWarnPlanCount(params) {
  return request.get('/workBench/todo/warnInvoicePlanCount ');
};

/**
 * *********************系统公告 开始***************************************************
 */
// 获取系统公告list
export var getNoticeList = function getNoticeList(params) {
  return request.post('/workBench/systemNotice/list', params);
};

// 获取所有未读消息数量
export var getNotReadCount = function getNotReadCount() {
  return request.get('/workBench/systemNotice/notReadCount');
};

// 获取最新的未读公告
export var getNewNotice = function getNewNotice() {
  return request.get('/workBench/systemNotice/last');
};

// 清除所有系统消息
export var clearSystemNotReadCount = function clearSystemNotReadCount(filter) {
  return request.put("/workBench/systemNotice/clear", filter, {
    isMore: true
  });
};

// 批量清除系统消息
export var clearSystemNotReadBatch = function clearSystemNotReadBatch(filter) {
  return request.put("/workBench/systemNotice/beRead", filter, {
    isMore: true
  });
};
/**
 *  *********************系统公告 结束***************************************************
 */

/**
 * *********************消息提醒 开始***************************************************
 */
// 获取 消息提醒 列表
export var getAlertList = function getAlertList(params) {
  return request.post('/msg/page', params);
};

// 清除消息提醒 批量
export var clearAlertAll = function clearAlertAll(params) {
  return request.post('/msg/batchBeRead', params);
};

// 清除单个 消息提醒
export var clearAlertSingle = function clearAlertSingle(id) {
  return request.get("/msg/beRead/".concat(id));
};

// 消息提醒未读总数
export var getNotReadCountAler = function getNotReadCountAler() {
  return request.get('/msg/noRead/count');
};

/**
 *  *********************消息提醒 结束***************************************************
 */

// 销售简报
export var getSalesReport = function getSalesReport(params) {
  return request.get('/workBench/salesReport', {
    params: params
  });
};

// 公海将回收的客户
export var getHignSeasRecycle = function getHignSeasRecycle(params) {
  return request.post('/workBench/toHighSea', params);
};

// 遗忘客户
export var getForgottenClient = function getForgottenClient(params) {
  return request.get('/workBench/forgottenClient', {
    params: params
  });
};

// 订单提醒
export var getOrderWarn = function getOrderWarn(params) {
  return request.get('/workBench/orderWarn', {
    params: params
  });
};

// 业务订单
export var getOrderInfo = function getOrderInfo(params) {
  return request.get('/workBench/statisticalOrderInfo', {
    params: params
  });
};

// 获取应收收入
export var getTodoInvoices = function getTodoInvoices() {
  return request.get('/workBench/todo/invoices');
};

// 获取应收变更单
export var getTodoInvoicesChange = function getTodoInvoicesChange() {
  return request.get('/workBench/todo/invoicesChange');
};
export var getTodoAsaInvoices = function getTodoAsaInvoices() {
  return request.get('/workBench/todo/asa/invoices');
};
export var getTodoDspInvoices = function getTodoDspInvoices() {
  return request.get('/workBench/todo/dsp/invoices');
};

// 获取应收未销列表
export var getNotWriteOffPage = function getNotWriteOffPage(params) {
  return request.get('/workBench/todo/warnInvoiceNotWriteOff/page', {
    params: params
  });
};

// 应收未销导出
export var exportExcel = function exportExcel(params) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    responseType: 'arraybuffer'
  };
  return request(_objectSpread(_objectSpread({
    url: "/workBench/todo/warnInvoiceNotWriteOff/export",
    method: 'get'
  }, config), {}, {
    params: params
  }));
};

// 获取收款，付款，备用金数量
export var getFeeData = function getFeeData(params, moduleSubId) {
  return request.get('/workBench/fee/approvalData', {
    params: params,
    headers: {
      moduleSubId: moduleSubId
    },
    isMore: true
  });
};
import request from '@/plugins/request';
import QS from 'qs';

/**
 * 发起登陆
 */
export function doLogin(path, data) {
  return request({
    url: path,
    method: 'post',
    data: data
  });
}

//微信登录校验
export var wxLogin = function wxLogin(data) {
  return request.post("/system/callback/wx", data);
};

//获取系统用户信息
export var getUserInfo = function getUserInfo() {
  return request.get("/system/userInfo?refreshUserToken=".concat(true));
};

//退出登录
export var AccountLogout = function AccountLogout() {
  return request.put("/system/logout");
};

//密码修改
export var PasswordReset = function PasswordReset(data) {
  return request.put("/system/reset", data, {
    transformRequest: function transformRequest(v) {
      return QS.stringify(v);
    }
  });
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "i-layout-sider-logo",
    class: {
      "i-layout-sider-logo-dark": _vm.siderTheme === "dark"
    },
    staticStyle: {
      height: "40px",
      "line-height": "40px"
    }
  }, [_vm._m(0)]), _c("Menu", {
    ref: "menu",
    staticClass: "i-layout-menu-side i-scrollbar-hide",
    attrs: {
      accordion: _vm.menuAccordion,
      "active-name": _vm.activePath,
      "open-names": _vm.openNames,
      theme: _vm.siderTheme,
      width: "auto"
    }
  }, [_vm._l(_vm.filterSider, function (item, index) {
    return [item.children == null || !item.children.length || item.children.every(function (i) {
      return i.hide;
    }) ? _c("i-menu-side-item", {
      key: index,
      attrs: {
        menu: item
      }
    }) : _c("i-menu-side-submenu", {
      key: index,
      attrs: {
        menu: item
      }
    })];
  })], 2)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-align-center logo-wrap"
  }, [_c("img", {
    staticStyle: {
      width: "35px",
      height: "35px"
    },
    attrs: {
      src: require("@/assets/svg/logo.svg")
    }
  }), _c("span", {
    staticClass: "logo-title"
  }, [_vm._v("4K CRM")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };
import { getAuth } from '@/libs/auth';
export default {
  name: 'order-notice',
  path: '/order-notice',
  meta: {
    auth: true,
    title: '订单提醒'
  },
  component: function component() {
    return import('@/pages/dsp/contract-manage/order-notice');
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuth("order-notice");
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import dayjs from 'dayjs';
export default {
  name: 'dsp-month-calendar',
  props: {
    //   默认显示的日期类型，可选中英文和日期，
    monthType: {
      validator: function validator(v) {
        return ['en', 'cn', 'date'].includes(v);
      },
      default: 'cn'
    },
    //配置选项
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    //是否可以点击
    isPick: {
      type: Boolean,
      default: true
    },
    // 默认高亮的月份
    highLight: {
      type: Array,
      default: function _default() {
        return [6, 10, 11];
      }
    },
    //是否显示活动,活动时 7 11 12月，已写死在里面，可以按照格式自定义输入
    showActivity: {
      type: Boolean,
      default: true
    },
    showActivityIcon: {
      type: Boolean,
      default: true
    },
    activity: {
      type: Array,
      default: function _default() {
        return [{
          index: 6,
          name: 'Prime Day',
          icon: 'md-cart'
        }, {
          index: 10,
          name: '黑五',
          icon: 'md-star'
        }, {
          index: 11,
          name: '圣诞节',
          icon: 'md-snow'
        }];
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dateKey: {
      type: String,
      default: 'date'
    }
  },
  data: function data() {
    return {
      year: dayjs().format('YYYY'),
      now: dayjs().format('YYYY-MM'),
      monthList: [{
        index: 0,
        en: 'January',
        cn: '1月'
      }, {
        index: 1,
        en: 'February',
        cn: '2月'
      }, {
        index: 2,
        en: 'March',
        cn: '3月'
      }, {
        index: 3,
        en: 'April',
        cn: '4月'
      }, {
        index: 4,
        en: 'May',
        cn: '5月'
      }, {
        index: 5,
        en: 'June',
        cn: '6月'
      }, {
        index: 6,
        en: 'July',
        cn: '7月'
      }, {
        index: 7,
        en: 'Auguest',
        cn: '8月'
      }, {
        index: 8,
        en: 'September',
        cn: '9月'
      }, {
        index: 9,
        en: 'October',
        cn: '10月'
      }, {
        index: 10,
        en: 'November',
        cn: '11月'
      }, {
        index: 11,
        en: 'December',
        cn: '12月'
      }]
    };
  },
  watch: {},
  computed: {
    activityObj: function activityObj() {
      return this.activity.reduce(function (all, item) {
        all[item.index] = item;
        return all;
      }, {});
    },
    months: function months() {
      var _this = this;
      //生成选择的年份的对应月份表，并把对应月份的 data 挂载到这个月份上去。
      // 同时用 options的 disabledMonth函数处理月份是否被禁用编辑.
      var list = this.monthList.map(function (_ref) {
        var index = _ref.index,
          en = _ref.en,
          cn = _ref.cn;
        var date = "".concat(_this.year, "-").concat(String(index + 1).padStart(2, '0'));
        var dataIndex = _this.data.findIndex(function (item) {
          return item[_this.dateKey] === date;
        });
        var data = dataIndex >= 0 ? _this.data[dataIndex] : null;
        var month = {
          index: index,
          cn: cn,
          en: en,
          date: date
        };
        //月份数据，包括 data 、disabled 禁用，cellStyle单元格样式、high活动高亮等
        if (dataIndex >= 0) {
          month.dataIndex = dataIndex;
          month.data = data;
        }
        if (_this.options.disabledMonth && typeof _this.options.disabledMonth === 'function') {
          month.disabled = _this.options.disabledMonth(date, data);
        }
        if (_this.options.cellStyle) {
          month.style = _this.options.cellStyle(date, data);
        }
        if (_this.activityObj[index]) {
          month.high = true;
        }
        return month;
      });
      return [list.splice(0, 4), list.splice(0, 4), list.splice(0, 4)];
    }
  },
  methods: {
    //  控件 上下一年，今年
    editYear: function editYear(type) {
      var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      if (type) {
        this.year = dayjs(this.year)[type](num, 'year').format('YYYY');
      } else {
        this.year = dayjs().format('YYYY');
      }
    },
    //判断日期是否是过去
    isPast: function isPast(date) {
      return dayjs(date).isBefore(dayjs().format('YYYY-MM'));
    }
  },
  mounted: function mounted() {}
};
import "core-js/modules/es6.number.constructor";
import echarts from 'echarts';
export default {
  name: 'fk-echart-complicate',
  props: {
    // echart高度
    height: {
      type: [Number, String],
      default: 600
    },
    // 是否立即初始化一次视图
    immediate: {
      type: Boolean,
      default: false
    },
    notMerge: {
      type: Boolean,
      default: true
    },
    xAxis: Object,
    xAxisData: Array,
    series: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dataset: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  computed: {
    options: function options() {
      var options = {
        // title:{
        //     left:'center',
        //     text:'123',
        //     top:'5%'
        // },
        legend: {},
        tooltip: {
          trigger: 'axis'
        },
        xAxis: this.xAxis || {
          type: 'category'
        },
        yAxis: {
          gridIndex: 0
        },
        grid: {
          top: '55%'
        },
        dataset: this.dataset,
        series: this.series
      };
      return options;
    }
  },
  methods: {
    init: function init() {
      var _this = this;
      this.echart = echarts.init(this.$refs['chart']);
      this.echart.on('updateAxisPointer', function (event) {
        var xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          var dimension = xAxisInfo.value + 1;
          _this.echart.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']} ({d}%)'
              },
              encode: {
                value: dimension,
                tooltip: dimension
              }
            }
          });
        }
      });
      window.addEventListener('resize', this.echart.resize);
      if (this.immediate) this.update();
    },
    update: function update() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.options;
      this.echart.setOption(options, this.notMerge);
    }
  },
  watch: {
    options: function options(newOptions) {
      this.update(newOptions);
    }
  },
  beforeDestroied: function beforeDestroied() {
    window.removeEventListener('resize', this.echart.resize);
    this.echart.dispose();
  }
};
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
import { debounce } from 'xe-utils';
// 防抖
var search = debounce(function () {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  this.$emit('on-remote-search', value);
}, 500);
export default {
  name: 'fk-select-modal',
  props: {
    radioList: {
      default: function _default() {
        return [];
      },
      type: Array
    },
    value: {
      type: Boolean,
      default: false
    },
    radioValue: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      default: '选择'
    },
    options: {
      type: Object,
      default: function _default() {
        return {
          // 配置 check 的 label 取值配置
          valueKey: 'id',
          labelKey: 'name',
          descKey: 'email',
          descKey1: 'contactTag'
        };
      }
    }
  },
  watch: {
    value: function value(val) {
      this.show = val;
    }
  },
  computed: {
    label: function label() {
      var _this = this;
      return function (item) {
        var label = item[_this.options.labelKey];
        var remark = item[_this.options.descKey] ? "\uFF08".concat(item[_this.options.descKey], "\uFF09") : '';
        var desc1 = _this.options.descKey1 ? item[_this.options.descKey1] ? "[".concat(item[_this.options.descKey1], "]") : '' : '';
        return "".concat(label).concat(remark).concat(desc1);
      };
    }
  },
  data: function data() {
    return {
      show: false,
      modelValue: null,
      modelItem: null,
      searchWord: null
    };
  },
  mounted: function mounted() {
    this.show = this.value;
  },
  methods: {
    onVisibleChange: function onVisibleChange(value) {
      if (value) {
        // 初始化
        this.modelValue = this.radioValue;
      } else {
        // 关闭
        this.$emit('input', false);
      }
    },
    onOk: function onOk() {
      this.$emit('on-confirm', this.modelItem);
    },
    // 搜索词变化 ，触发外部搜索
    searchWordChange: function searchWordChange() {
      search.call(this, this.searchWord);
    },
    radioChange: function radioChange(value) {
      var _this2 = this;
      this.modelItem = this.radioList.find(function (v) {
        return v[_this2.options.valueKey] === value;
      });
    }
  }
};
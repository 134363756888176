import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
export default {
  name: 'fk-dropdown',
  props: {
    type: {
      default: 'text',
      // button
      validator: function validator(value) {
        return ['text', 'button'].includes(value);
      }
    },
    text: {
      default: '更多',
      type: String
    }
  },
  data: function data() {
    return {
      dropdownListLength: 1
    };
  },
  methods: {
    onClick: function onClick(name) {
      this.$emit('on-click', name);
    }
  },
  mounted: function mounted() {
    var _this = this;
    // 动态监听下拉子元素，根据长度确定是否显示
    this.$nextTick(function () {
      _this.dropdownListLength = _this.$refs['dropdown-list'].$children.length;
    });
  },
  updated: function updated() {
    var _this2 = this;
    // 动态监听下拉子元素，根据长度确定是否显示
    this.$nextTick(function () {
      _this2.dropdownListLength = _this2.$refs['dropdown-list'].$children.length;
    });
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Dropdown", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownListLength > 0,
      expression: "dropdownListLength > 0"
    }],
    staticClass: "dropdown-wrap",
    attrs: {
      transfer: ""
    },
    on: {
      "on-click": _vm.onClick
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c("DropdownMenu", {
          ref: "dropdown-list"
        }, [_vm._t("default")], 2)];
      },
      proxy: true
    }], null, true)
  }, [_vm.type === "text" ? [_c("a", {
    attrs: {
      href: "javascript:void(0)"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.text) + "\n\t\t\t"), _c("Icon", {
    attrs: {
      type: "ios-arrow-down"
    }
  })], 1)] : [_c("Button", [_vm._v("\n\t\t\t" + _vm._s(_vm.text) + "\n\t\t\t"), _c("Icon", {
    attrs: {
      type: "ios-arrow-down"
    }
  })], 1)]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
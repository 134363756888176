import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Modal", {
    staticClass: "customModal",
    attrs: {
      loading: true,
      "mask-closable": false,
      width: 750,
      title: "自定义显示列"
    },
    on: {
      "on-visible-change": _vm.onVisibleChange
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c("div", {
    staticClass: "customFields-content flexbox"
  }, [_c("div", {
    staticClass: "left-wrap"
  }, [_c("Card", {
    attrs: {
      bordered: false,
      "dis-hover": ""
    }
  }, [_c("div", {
    staticClass: "card-title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h3", [_vm._v("可选字段")]), _c("span", [_vm._v("（勾选的字段会在列表显示，灰色选中项不支持隐藏和排序）")])]), _c("div", {
    staticClass: "list"
  }, [_c("Checkbox", {
    on: {
      "on-change": _vm.selectAllChange
    },
    model: {
      value: _vm.selectAll,
      callback: function callback($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  }, [_vm._v("全部")]), _vm._l(_vm.columnsByUserInfoCopy, function (item) {
    return _c("Checkbox", {
      key: item.id,
      attrs: {
        disabled: item.isFixed
      },
      on: {
        "on-change": function onChange($event) {
          return _vm.checkChange(item);
        }
      },
      model: {
        value: item.isChoose,
        callback: function callback($$v) {
          _vm.$set(item, "isChoose", $$v);
        },
        expression: "item.isChoose"
      }
    }, [_vm._v(_vm._s(item.name) + "\n\t\t\t\t\t")]);
  })], 2)]), _vm.columnsForCustomFieldCopy.length > 0 ? _c("Card", {
    attrs: {
      bordered: false,
      "dis-hover": ""
    }
  }, [_c("div", {
    staticClass: "card-title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h3", [_vm._v("自定义配置字段")]), _c("span", [_vm._v("（自定义配置的字段，勾选后会在列表显示，但不支持排序）")])]), _c("div", {
    staticClass: "list"
  }, [_c("Checkbox", {
    on: {
      "on-change": _vm.selectAllChangeForCustom
    },
    model: {
      value: _vm.selectAllForCustom,
      callback: function callback($$v) {
        _vm.selectAllForCustom = $$v;
      },
      expression: "selectAllForCustom"
    }
  }, [_vm._v("全部")]), _vm._l(_vm.columnsForCustomFieldCopy, function (item) {
    return _c("Checkbox", {
      key: item.id,
      attrs: {
        disabled: item.isFixed
      },
      on: {
        "on-change": function onChange($event) {
          return _vm.checkChangeForCustom(item);
        }
      },
      model: {
        value: item.isChoose,
        callback: function callback($$v) {
          _vm.$set(item, "isChoose", $$v);
        },
        expression: "item.isChoose"
      }
    }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t\t")]);
  })], 2)]) : _vm._e()], 1), _c("div", {
    staticClass: "line"
  }), _c("div", {
    staticClass: "right-wrap"
  }, [_c("Card", {
    attrs: {
      bordered: false,
      "dis-hover": ""
    }
  }, [_c("div", {
    staticClass: "card-title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h3", [_vm._v("字段拖动排序")])]), _c("draggable", {
    staticClass: "dragWrap",
    attrs: {
      scroll: true,
      animation: "300",
      "chosen-class": "movechose",
      "force-fallback": "true",
      tag: "div"
    },
    model: {
      value: _vm.sortListVmodel,
      callback: function callback($$v) {
        _vm.sortListVmodel = $$v;
      },
      expression: "sortListVmodel"
    }
  }, [_c("transition-group", [_vm._l(_vm.sortListVmodel, function (item, index) {
    return [_c("div", {
      key: index,
      staticClass: "drag-item break-all"
    }, [_c("Icon", {
      attrs: {
        size: 22,
        type: "md-reorder"
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])], 1)];
  })], 2)], 1)], 1)], 1)]), _c("div", {
    staticClass: "btn-box",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("Button", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.$emit("input", false);
      }
    }
  }, [_vm._v("取消")]), _c("Button", {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v("保存")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };